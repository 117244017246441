._ovs-contain {overscroll-behavior:contain;}
.is_Text .is_Text {display:inline-flex;}
._dsp_contents {display:contents;}
:root {--color-1:#00ffa6;--color-2:rgba(21, 21, 21, 0);--color-3:rgba(21, 21, 21, 0.6);--color-4:rgba(225, 225, 225, 0.1);--color-5:rgba(185, 185, 185, 0.1);--radius-1:0px;--radius-2:3px;--radius-3:5px;--radius-4:7px;--radius-5:9px;--radius-6:10px;--radius-7:16px;--radius-8:19px;--radius-9:22px;--radius-10:26px;--radius-11:34px;--radius-12:42px;--radius-13:50px;--radius-14:9px;--space-1:0px;--space-5:2px;--space-7:7px;--space-9:13px;--space-11:18px;--space-14:24px;--space-15:32px;--space-16:39px;--space-17:46px;--space-18:53px;--space-19:60px;--space-20:74px;--space-21:88px;--space-22:102px;--space-23:116px;--space-24:130px;--space-25:144px;--space-26:144px;--space-27:158px;--space-28:172px;--space-29:186px;--space-2:0.5px;--space-3:1px;--space-4:1.5px;--space-6:4px;--space-8:10px;--space-10:16px;--space-12:18px;--space-13:21px;--space-30:-0.5px;--space-31:-1px;--space-32:-1.5px;--space-33:-2px;--space-34:-4px;--space-35:-7px;--space-36:-10px;--space-37:-13px;--space-38:-16px;--space-39:-18px;--space-40:-18px;--space-41:-21px;--space-42:-24px;--space-43:-32px;--space-44:-39px;--space-45:-46px;--space-46:-53px;--space-47:-60px;--space-48:-74px;--space-49:-88px;--space-50:-102px;--space-51:-116px;--space-52:-130px;--space-53:-144px;--space-54:-144px;--space-55:-158px;--space-56:-172px;--space-57:-186px;--size-1:0px;--size-5:20px;--size-7:28px;--size-9:36px;--size-11:44px;--size-14:52px;--size-15:64px;--size-16:74px;--size-17:84px;--size-18:94px;--size-19:104px;--size-20:124px;--size-21:144px;--size-22:164px;--size-23:184px;--size-24:204px;--size-25:224px;--size-26:224px;--size-27:244px;--size-28:264px;--size-29:284px;--size-2:2px;--size-3:4px;--size-4:8px;--size-6:24px;--size-8:32px;--size-10:40px;--size-12:44px;--size-13:48px}
:root .font_heading, :root .t_lang-heading-default .font_heading {--f-fa:Inter, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;--f-li-1:22px;--f-li-2:23px;--f-li-3:24px;--f-li-4:25px;--f-li-5:24px;--f-li-6:27px;--f-li-7:32px;--f-li-8:35px;--f-li-9:40px;--f-li-10:53px;--f-li-11:66px;--f-li-12:73px;--f-li-13:84px;--f-li-14:106px;--f-li-15:130px;--f-li-16:152px;--f-li-17:25px;--f-we-1:400;--f-we-2:400;--f-we-3:400;--f-we-4:400;--f-we-5:400;--f-we-6:400;--f-we-7:700;--f-we-8:700;--f-we-9:700;--f-we-10:700;--f-we-11:700;--f-we-12:700;--f-we-13:700;--f-we-14:700;--f-we-15:700;--f-we-16:700;--f-we-17:700;--f-21-1:2px;--f-21-2:2px;--f-21-3:2px;--f-21-4:2px;--f-21-5:2px;--f-21-6:1px;--f-21-7:0px;--f-21-8:0px;--f-21-9:-1px;--f-21-10:-1.5px;--f-21-11:-1.5px;--f-21-12:-2px;--f-21-13:-2px;--f-21-14:-3px;--f-21-15:-4px;--f-21-16:-4px;--f-21-17:-4px;--f-si-1:11px;--f-si-2:12px;--f-si-3:13px;--f-si-4:14px;--f-si-5:13px;--f-si-6:15px;--f-si-7:20px;--f-si-8:23px;--f-si-9:32px;--f-si-10:44px;--f-si-11:55px;--f-si-12:62px;--f-si-13:72px;--f-si-14:92px;--f-si-15:114px;--f-si-16:134px;--f-si-17:14px;--f-tr-1:uppercase;--f-tr-2:uppercase;--f-tr-3:uppercase;--f-tr-4:uppercase;--f-tr-5:uppercase;--f-tr-6:uppercase;--f-tr-7:none;--f-tr-8:none;--f-tr-9:none;--f-tr-10:none;--f-tr-11:none;--f-tr-12:none;--f-tr-13:none;--f-tr-14:none;--f-tr-15:none;--f-tr-16:none;--f-tr-17:none}
:root .font_body, :root .t_lang-body-default .font_body {--f-fa:Inter, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;--f-li-1:16px;--f-li-2:21px;--f-li-3:22px;--f-li-4:23px;--f-li-5:26px;--f-li-6:28px;--f-li-7:30px;--f-li-8:33px;--f-li-9:41px;--f-li-10:59px;--f-li-11:69px;--f-li-12:76px;--f-li-13:87px;--f-li-14:109px;--f-li-15:133px;--f-li-16:155px;--f-li-17:23px;--f-we-1:400;--f-we-2:400;--f-we-3:400;--f-we-4:400;--f-we-5:400;--f-we-6:400;--f-we-7:600;--f-we-8:600;--f-we-9:600;--f-we-10:600;--f-we-11:600;--f-we-12:600;--f-we-13:600;--f-we-14:600;--f-we-15:600;--f-we-16:600;--f-we-17:600;--f-21-1:0px;--f-21-2:0px;--f-21-3:0px;--f-21-4:0px;--f-21-5:0px;--f-21-6:0px;--f-21-7:0px;--f-21-8:0px;--f-21-9:0px;--f-21-10:0px;--f-21-11:0px;--f-21-12:0px;--f-21-13:0px;--f-21-14:0px;--f-21-15:0px;--f-21-16:0px;--f-21-17:0px;--f-si-1:11px;--f-si-2:12px;--f-si-3:13px;--f-si-4:14px;--f-si-5:16px;--f-si-6:18px;--f-si-7:20px;--f-si-8:23px;--f-si-9:30px;--f-si-10:46px;--f-si-11:55px;--f-si-12:62px;--f-si-13:72px;--f-si-14:92px;--f-si-15:114px;--f-si-16:134px;--f-si-17:14px}
:root .font_mono, :root .t_lang-mono-default .font_mono {--f-fa:"ui-monospace", "SFMono-Regular", "SF Mono", Menlo, Consolas, "Liberation Mono", monospace;--f-si-1:11px;--f-si-2:12px;--f-si-3:13px;--f-si-4:14px;--f-si-5:16px;--f-si-6:18px;--f-si-7:20px;--f-si-8:22px;--f-si-9:30px;--f-si-10:42px;--f-si-11:52px;--f-si-12:62px;--f-si-13:72px;--f-si-14:92px;--f-si-15:114px;--f-si-16:124px;--f-li-1:16.5px;--f-li-2:18px;--f-li-3:19.5px;--f-li-4:21px;--f-li-5:24px;--f-li-6:27px;--f-li-7:30px;--f-li-8:33px;--f-li-9:45px;--f-li-10:63px;--f-li-11:78px;--f-li-12:93px;--f-li-13:108px;--f-li-14:138px;--f-li-15:171px;--f-li-16:186px;--f-we-1:500;--f-we-2:500;--f-we-3:500;--f-we-4:500;--f-we-5:500;--f-we-6:500;--f-we-7:500;--f-we-8:500;--f-we-9:500;--f-we-10:500;--f-we-11:500;--f-we-12:500;--f-we-13:500;--f-we-14:500;--f-we-15:500;--f-we-16:500;--f-21-1:0px;--f-21-2:0px;--f-21-3:0px;--f-21-4:0px;--f-21-5:0px;--f-21-6:0px;--f-21-7:0px;--f-21-8:0px;--f-21-9:0px;--f-21-10:0px;--f-21-11:0px;--f-21-12:0px;--f-21-13:0px;--f-21-14:0px;--f-21-15:0px;--f-21-16:0px}
:root .font_silkscreen, :root .t_lang-silkscreen-default .font_silkscreen {--f-fa:Silkscreen, Fira Code, Monaco, Consolas, Ubuntu Mono, monospace;--f-si-1:11px;--f-si-2:12px;--f-si-3:13px;--f-si-4:14px;--f-si-5:15px;--f-si-6:16px;--f-si-7:18px;--f-si-8:21px;--f-si-9:28px;--f-si-10:42px;--f-si-11:52px;--f-si-12:62px;--f-si-13:72px;--f-si-14:92px;--f-si-15:114px;--f-si-16:124px;--f-li-1:19px;--f-li-2:20px;--f-li-3:22px;--f-li-4:23px;--f-li-5:24px;--f-li-6:25px;--f-li-7:28px;--f-li-8:31px;--f-li-9:40px;--f-li-10:56px;--f-li-11:68px;--f-li-12:80px;--f-li-13:92px;--f-li-14:116px;--f-li-15:143px;--f-li-16:155px;--f-we-1:300;--f-we-2:300;--f-we-3:300;--f-we-4:300;--f-we-5:300;--f-we-6:300;--f-we-7:300;--f-we-8:300;--f-we-9:300;--f-we-10:300;--f-we-11:300;--f-we-12:300;--f-we-13:300;--f-we-14:300;--f-we-15:300;--f-we-16:300;--f-21-1:1px;--f-21-2:1px;--f-21-3:1px;--f-21-4:1px;--f-21-5:3px;--f-21-6:3px;--f-21-7:3px;--f-21-8:3px;--f-21-9:-2px;--f-21-10:-3px;--f-21-11:-3px;--f-21-12:-4px;--f-21-13:-4px;--f-21-14:-4px;--f-21-15:-4px;--f-21-16:-4px}
:root.t_dark, :root.t_dark .t_light .t_dark, :root.t_light .t_dark, :root.t_light .t_dark .t_light .t_dark {--accentBackground:#a5a5a5;--accentColor:var(--color-1);--background0:var(--color-2);--background025:rgba(21, 21, 21, 0.25);--background05:rgba(21, 21, 21, 0.5);--background075:rgba(21, 21, 21,0.75);--color1:#050505;--color2:#151515;--color3:#191919;--color4:#232323;--color5:#282828;--color6:#323232;--color7:#424242;--color8:#494949;--color9:#545454;--color10:#626262;--color11:#a5a5a5;--color12:#fff;--color0:#000000;--color025:#000000;--color05:#000000;--color075:#000000;--background:#151515;--backgroundHover:#191919;--backgroundPress:#232323;--backgroundFocus:#282828;--borderColor:#282828;--borderColorHover:#323232;--borderColorPress:#000000;--borderColorFocus:#282828;--color:#fff;--colorHover:#a5a5a5;--colorPress:#fff;--colorFocus:#a5a5a5;--colorTransparent:rgba(255,255,255,0);--placeholderColor:#545454;--outlineColor:rgba(0,0,0,0.5);}
@media(prefers-color-scheme:dark){
    body{background:var(--background);color:var(--color)}
    :root, .t_light .t_dark {--accentBackground:#a5a5a5;--accentColor:var(--color-1);--background0:var(--color-2);--background025:rgba(21, 21, 21, 0.25);--background05:rgba(21, 21, 21, 0.5);--background075:rgba(21, 21, 21,0.75);--color1:#050505;--color2:#151515;--color3:#191919;--color4:#232323;--color5:#282828;--color6:#323232;--color7:#424242;--color8:#494949;--color9:#545454;--color10:#626262;--color11:#a5a5a5;--color12:#fff;--color0:#000000;--color025:#000000;--color05:#000000;--color075:#000000;--background:#151515;--backgroundHover:#191919;--backgroundPress:#232323;--backgroundFocus:#282828;--borderColor:#282828;--borderColorHover:#323232;--borderColorPress:#000000;--borderColorFocus:#282828;--color:#fff;--colorHover:#a5a5a5;--colorPress:#fff;--colorFocus:#a5a5a5;--colorTransparent:rgba(255,255,255,0);--placeholderColor:#545454;--outlineColor:rgba(0,0,0,0.5);}
  }
.t_dark ::selection{background:var(--color5);color:var(--color11)}
:root.t_dark .t_light, :root.t_dark .t_light .t_dark .t_light, :root.t_light, :root.t_light .t_dark .t_light {--accentBackground:#a5a5a5;--accentColor:var(--color-1);--background0:rgba(255, 255, 255,0);--background025:rgba(255, 255, 255, 0.25);--background05:rgba(255, 255, 255,0.5);--background075:rgba(255, 255, 255,0.75);--color1:#fff;--color2:#f8f8f8;--color3:hsl(0, 0%, 96.3%);--color4:hsl(0, 0%, 94.1%);--color5:hsl(0, 0%, 92.0%);--color6:hsl(0, 0%, 90.0%);--color7:hsl(0, 0%, 88.5%);--color8:hsl(0, 0%, 81.0%);--color9:hsl(0, 0%, 56.1%);--color10:hsl(0, 0%, 50.3%);--color11:hsl(0, 0%, 42.5%);--color12:hsl(0, 0%, 9.0%);--color0:#000000;--color025:#000000;--color05:#000000;--color075:#000000;--background:#f8f8f8;--backgroundHover:hsl(0, 0%, 96.3%);--backgroundPress:hsl(0, 0%, 94.1%);--backgroundFocus:hsl(0, 0%, 92.0%);--backgroundStrong:#fff;--borderColor:hsl(0, 0%, 92.0%);--borderColorHover:hsl(0, 0%, 90.0%);--borderColorPress:hsl(0, 0%, 92.0%);--borderColorFocus:hsl(0, 0%, 94.1%);--color:hsl(0, 0%, 9.0%);--colorHover:hsl(0, 0%, 42.5%);--colorPress:hsl(0, 0%, 9.0%);--colorFocus:hsl(0, 0%, 42.5%);--colorTransparent:rgba(10,10,10,0);--placeholderColor:hsl(0, 0%, 56.1%);--outlineColor:rgba(255,255,255,0.5);}
@media(prefers-color-scheme:light){
    body{background:var(--background);color:var(--color)}
    :root, .t_dark .t_light {--accentBackground:#a5a5a5;--accentColor:var(--color-1);--background0:rgba(255, 255, 255,0);--background025:rgba(255, 255, 255, 0.25);--background05:rgba(255, 255, 255,0.5);--background075:rgba(255, 255, 255,0.75);--color1:#fff;--color2:#f8f8f8;--color3:hsl(0, 0%, 96.3%);--color4:hsl(0, 0%, 94.1%);--color5:hsl(0, 0%, 92.0%);--color6:hsl(0, 0%, 90.0%);--color7:hsl(0, 0%, 88.5%);--color8:hsl(0, 0%, 81.0%);--color9:hsl(0, 0%, 56.1%);--color10:hsl(0, 0%, 50.3%);--color11:hsl(0, 0%, 42.5%);--color12:hsl(0, 0%, 9.0%);--color0:#000000;--color025:#000000;--color05:#000000;--color075:#000000;--background:#f8f8f8;--backgroundHover:hsl(0, 0%, 96.3%);--backgroundPress:hsl(0, 0%, 94.1%);--backgroundFocus:hsl(0, 0%, 92.0%);--backgroundStrong:#fff;--borderColor:hsl(0, 0%, 92.0%);--borderColorHover:hsl(0, 0%, 90.0%);--borderColorPress:hsl(0, 0%, 92.0%);--borderColorFocus:hsl(0, 0%, 94.1%);--color:hsl(0, 0%, 9.0%);--colorHover:hsl(0, 0%, 42.5%);--colorPress:hsl(0, 0%, 9.0%);--colorFocus:hsl(0, 0%, 42.5%);--colorTransparent:rgba(10,10,10,0);--placeholderColor:hsl(0, 0%, 56.1%);--outlineColor:rgba(255,255,255,0.5);}
  }
.t_light ::selection{background:var(--color5);color:var(--color11)}
